import classNames from 'classnames';
import styles from './GridColumns.module.scss';
export interface GridColumnsProps {
	contentColumns: {
		content: React.ReactNode;
		noSpacing?: boolean;
		noBackground?: boolean;
		backgroundColor?:
			| 'White'
			| 'Primary100'
			| 'Primary80'
			| 'Primary60'
			| 'Primary40'
			| 'Primary20'
			| 'Sand'
			| 'Sand50'
			| 'Theme'
			| 'Theme60'
			| 'Theme30';
	}[];
	widthRatio?: '20/80' | '33/66' | '40/60' | '50/50' | '60/40' | '66/33' | '80/20';
	noGutter?: boolean;
	noTopMargin?: boolean;
}

export const GridColumns: React.FC<GridColumnsProps> = ({ contentColumns, widthRatio = '50/50', noGutter = false, noTopMargin = false }) => {
	const ratioModifier = widthRatio.replace('/', 'x');
	return (
		<div
			className={classNames(styles.GridColumns, styles[`GridColumns___${ratioModifier}`], {
				[styles.GridColumns___noGutter]: noGutter,
				[styles.GridColumns___noTopMargin]: noTopMargin,
			})}
		>
			{contentColumns?.map((column, index) => {
				return (
					<div
						className={classNames(
							styles.GridColumns_column,
							column.noSpacing && styles.GridColumns_column___noSpacing,
							column.noBackground && styles.GridColumns_column___noBackground,
							styles[`GridColumns_column___${column.backgroundColor}`],
						)}
						key={index}
					>
						{column.content}
					</div>
				);
			})}
		</div>
	);
};
